<template>
  <div class="container">
    <card-component
      class="has-table has-mobile-sort-spaced"
      style="padding: 3em"
    >
      <h1 class="title is-2" style="color: #111111">ສາຂາ - ຈຸດຮັບຝາກເຄື່ອງ</h1>
      <multiselect
        v-model="province"
        :options="provinces"
        track-by="id"
        label="name"
        placeholder="ສາຂາຕາມແຂວງ"
        select-label=""
        selected-label=""
        deselect-label=""
        @input="changeProvince()"
      >
      </multiselect>
      <hr />
      <b-table
        :loading="isLoading"
        :paginated="perPage < branches.length"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        :data="branches"
        :current-page.sync="currentPage"
        :pagination-rounded="true"
      >
        <b-table-column label="#" v-slot="props">
          {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
        </b-table-column>
        <b-table-column label="ແຂວງ" field="name" sortable v-slot="props">
          {{ props.row.district.province.name }}
        </b-table-column>
        <b-table-column label="ເມືອງ" field="name" sortable v-slot="props">
          {{ props.row.district.name }}
        </b-table-column>
        <b-table-column label="ຊື່ສາຂາ" field="name" sortable v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="ເບີໂທ" field="tel" sortable v-slot="props">
          {{ props.row.tel }}
        </b-table-column>
        <b-table-column label="ລະຫັດສາຂາ" field="code" sortable v-slot="props">
          {{ props.row.code }}
        </b-table-column>
        <b-table-column
          label="ແຜນທີ່ທີ່ຕັ້ງ"
          field="code"
          sortable
          v-slot="props"
        >
          <a
            :href="`https://www.google.com/maps/place/@${props.row.latitude},${props.row.longitude},21z`"
            target="_blank"
            class="button is-info"
          >
            <b-icon :icon="'map-marker'"></b-icon>
          </a>
        </b-table-column>
        <empty-table v-if="branches.length == 0" :isLoading="isLoading" />
      </b-table>
    </card-component>
  </div>
</template>

<script>
import Axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "Branch",
  components: { CardComponent, EmptyTable, Multiselect },
  data() {
    return {
      activeTab: 0,
      currentPage: 1,
      perPage: 20,
      title: "ສາຂາ",
      branch: null,
      allbranches: [],
      branches: [],
      provinces: [],
      province: null,
      isLoading: false,
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    changeProvince() {
      console.log(this.province);
      if (this.province) {
        this.branches = this._.filter(
          this.allbranches,
          (b) => b.district.province.id == this.province.id
        );
      } else {
        this.branches = this.allbranches;
      }
      console.log(this.branches);
    },
    get() {
      this.isLoading = true;
      Axios.get("branches")
        .then((r) => {
          this.allbranches = r;
          this.branches = r;
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
          this.isLoading = false;
        });
      Axios.get("provinces")
        .then((r) => {
          this.provinces = r;
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
